import React from "react";
import LikeLogoImg from "../../../assets/like_icon.svg";

type Props = {
    likedCount: number;
    disabled: boolean;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
};

export const LikedButton: React.FC<Props> = (props: Props) => {
    const { likedCount = 0, disabled = false, handleClick, className } = props;

    const handleClickWrapper = (e: React.MouseEvent<HTMLDivElement>) => {
        if (disabled) {
            e.stopPropagation();
        }
    };

    return (
        <div
            onClick={(e) => handleClickWrapper(e)}
            className={`gtm-like-button ${className}`}
        >
            <button
                className={`min-w-14 h-7 w-fit max-w-full rounded-full px-2 text-2xl text-white
                ${disabled ? "pointer-events-none bg-gray-300" : "bg-primary"}`}
                onClick={(e) => handleClick(e)}
                disabled={disabled}
            >
                <div className="flex">
                    <img src={LikeLogoImg} alt="LikeLogoImg" className="w-5" />
                    <div className="ml-1 text-base">{likedCount}</div>
                </div>
            </button>
        </div>
    );
};
