/* tslint:disable */
/* eslint-disable */
/**
 * Twioku-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostUsersMeAnswererUploadProfileImageRequest
 */
export interface PostUsersMeAnswererUploadProfileImageRequest {
    /**
     * 
     * @type {string}
     * @memberof PostUsersMeAnswererUploadProfileImageRequest
     */
    fileImageToBase64: string;
    /**
     * 
     * @type {string}
     * @memberof PostUsersMeAnswererUploadProfileImageRequest
     */
    answererId: string;
}

/**
 * Check if a given object implements the PostUsersMeAnswererUploadProfileImageRequest interface.
 */
export function instanceOfPostUsersMeAnswererUploadProfileImageRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fileImageToBase64" in value;
    isInstance = isInstance && "answererId" in value;

    return isInstance;
}

export function PostUsersMeAnswererUploadProfileImageRequestFromJSON(json: any): PostUsersMeAnswererUploadProfileImageRequest {
    return PostUsersMeAnswererUploadProfileImageRequestFromJSONTyped(json, false);
}

export function PostUsersMeAnswererUploadProfileImageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostUsersMeAnswererUploadProfileImageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileImageToBase64': json['fileImageToBase64'],
        'answererId': json['answererId'],
    };
}

export function PostUsersMeAnswererUploadProfileImageRequestToJSON(value?: PostUsersMeAnswererUploadProfileImageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileImageToBase64': value.fileImageToBase64,
        'answererId': value.answererId,
    };
}

