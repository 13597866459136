import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    cognitoUserPasswordReset,
    getCSRFToken,
    sendPasswordResetMail,
    signin,
    signout,
    signup,
} from "../../../api/auth";
import { showToast } from "@twioku/common_components";
import { useAppState, useSetAppState } from "../../../stores/appContext";

// 下記の正規表現は以下の条件を満たすパスワードを許容する
// - 8文字以上
// - 英小文字、英大文字、数字をそれぞれ1文字以上含む
// ※記号等は許容する
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

// FIXME: 用途ごとに hook を分割し、且つ tanstack query を使う
export const useAuth = () => {
    const state = useAppState();
    const { csrfToken } = state;
    const setAppState = useSetAppState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const signinUser = async (
        email: string,
        password: string,
        callback: () => void
    ) => {
        setIsLoading(true);
        try {
            const res = await signin(email, password);
            if (res) {
                fetchCSRFToken();
                callback();
            }
        } catch (e) {
            showToast(
                "処理を正常に実行できませんでした。\n管理者までお問合せください。",
                "error"
            );
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const signupUser = async (
        email: string,
        password: string,
        callback: () => void
    ) => {
        const isValid = Boolean(password.match(PASSWORD_REGEX));
        if (!isValid) {
            showToast(
                "パスワードが不正です。\n条件を満たすよう入力してください。",
                "error"
            );
            return;
        }
        setIsLoading(true);
        await signup(email, password)
            .then(() => {
                callback();
            })
            .catch((error) => {
                console.error(error);
                const message =
                    error?.response?.data?.message ??
                    "処理を正常に実行できませんでした。"; // FIXME: messageがあれば、というやや適当な感じ
                showToast(`${message}\n管理者までお問合せください。`, "error");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const signoutUser = async () => {
        await signout(csrfToken)
            .then(() => {
                setAppState((state) => ({
                    ...state,
                    user: null,
                    csrfToken: "",
                    isLoggedIn: false,
                    hamburgerMenuInfo: {
                        menuAnimation: "",
                        hamburgerMenuIsOpen: false,
                    },
                }));
                showToast("ログアウトしました。");
                navigate("/signin");
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const fetchCSRFToken = () => {
        getCSRFToken().then((token) => {
            setAppState((state) => ({ ...state, csrfToken: token }));
        });
    };

    const fetchSendPasswordResetMail = async (
        email: string,
        callback: () => void
    ): Promise<void> => {
        if (!email) {
            showToast(
                "Twiokuで登録しているメールアドレスを入力してください。",
                "error"
            );
            return;
        }

        try {
            setIsLoading(true);
            await sendPasswordResetMail(email).then(() => {
                callback();
            });
        } catch {
            showToast(
                "エラーが発生しました。管理者にお問い合わせください。",
                "error"
            );
        } finally {
            setIsLoading(false);
        }
    };

    const passwordReset = async (
        userName: string | null,
        confirmationCode: string | null,
        newPassword: string,
        confirmNewPassword: string,
        callback: () => void
    ): Promise<void> => {
        if (!userName || !confirmationCode) {
            showToast("予期しないエラーが発生しました。", "error");
            return;
        }

        if (!newPassword || !confirmNewPassword) {
            showToast(
                "新パスワードまたは再入力パスワードを入力してください。",
                "error"
            );
            return;
        }

        const isValid = Boolean(newPassword.match(PASSWORD_REGEX));
        if (!isValid) {
            showToast(
                "パスワードが不正です。\n条件を満たすよう入力してください。",
                "error"
            );
            return;
        }

        if (newPassword !== confirmNewPassword) {
            showToast(
                "新パスワードと再入力パスワードが一致しません。",
                "error"
            );
            return;
        }

        try {
            setIsLoading(true);
            await cognitoUserPasswordReset(
                userName,
                confirmationCode,
                newPassword
            ).then(() => {
                callback();
            });
        } catch {
            showToast("予期しないエラーが発生しました。", "error");
        } finally {
            setIsLoading(false);
        }
    };

    return {
        csrfToken,
        signinUser,
        signoutUser,
        signupUser,
        fetchCSRFToken,
        fetchSendPasswordResetMail,
        passwordReset,
        isLoading,
    };
};
