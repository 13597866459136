import React, {
    createRef,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { getMe } from "../api/user";
import { ProfileImage } from "@twioku/common_components";
import { useLoadable } from "../libs/hooks";
import { showToast } from "@twioku/common_components";
import { User } from "../api/generated";
import { useUser } from "../features/user/hooks/useUser";
import { ConfirmContentInput } from "../features/newAnswererInfo/components/ConfirmContentInput";
import { Button, SpinAnimation } from "@twioku/common_components";
import { EMAIL_REGEX } from "../libs/constants";

const initialUser: User = {
    id: "",
    email: "",
    profileImagePath: "",
};

// FIXME: react-hook-form を使う
export const ProfilePage: React.FC = () => {
    const {
        uploadProfileImage,
        updateEmail,
        isLoading: isUpdating,
    } = useUser();
    const { data: me, isLoading } = useLoadable(() => getMe());

    const [user, setUser] = useState<User>(initialUser);
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");

    const inputRef = createRef<HTMLInputElement>();
    const handleProfileChangeButtonClick = () => inputRef.current?.click();

    const isChanged = useMemo(() => {
        return user.email !== email;
    }, [user, email]);

    const handleClickChangeProfileImage = useCallback(
        async (fileList: FileList | null) => {
            if (!fileList || fileList.length === 0) return;
            const file = fileList[0];

            const response = await uploadProfileImage(file);
            if (response.isSuccess) {
                setUser((prevUser) => {
                    return {
                        ...prevUser,
                        profileImagePath: response.uploadFilePath,
                    };
                });
            }
            showToast(
                response.message,
                response.isSuccess ? "success" : "error"
            );
        },
        [uploadProfileImage]
    );

    const handleChangeEmail = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
            if (EMAIL_REGEX.test(e.target.value)) {
                setEmailError("");
            } else {
                setEmailError("メールアドレスが不正です");
            }
        },
        []
    );

    const handleClickSaveChangeButton = useCallback(async () => {
        await updateEmail(email)
            .then(() => {
                showToast(
                    "メールアドレス確認のメールをお送りしましたので、ご確認ください。"
                );
                setUser((prevUser) => ({ ...prevUser, email }));
            })
            .catch((error) => {
                console.error(error);
                showToast(
                    "エラーが発生しました。\n管理者にお問合せください。",
                    "error"
                );
            });
    }, [updateEmail, email]);

    useEffect(() => {
        if (!me) return;
        setUser(me);
        setEmail(me.email);
    }, [me]);

    if (isLoading) {
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <SpinAnimation />
            </div>
        );
    }

    return (
        <>
            <input
                ref={inputRef}
                data-testid="change-photo-input"
                type="file"
                className="absolute hidden"
                accept="image/jpeg,image/png,image/gif"
                onChange={(e) => handleClickChangeProfileImage(e.target.files)}
            />
            <div className="m-4">
                <h1 className="mb-4 text-xl font-bold">あなたのプロフィール</h1>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col items-center gap-2">
                        <div
                            className="cursor-pointer"
                            data-testid="change-photo-button"
                            onClick={handleProfileChangeButtonClick}
                        >
                            <ProfileImage
                                src={user.profileImagePath}
                                size="large"
                            />
                        </div>
                    </div>
                    <div>
                        <ConfirmContentInput
                            label="メールアドレス"
                            value={email}
                            name="email"
                            handleChangeText={handleChangeEmail}
                            errorMessage={emailError}
                        />
                    </div>
                    <div className="fixed bottom-0 left-1/2 w-full -translate-x-1/2 bg-white text-center">
                        <Button
                            onClick={handleClickSaveChangeButton}
                            className="mb-8 mt-2 h-12 w-11/12"
                            disabled={
                                !isChanged || isLoading || emailError !== ""
                            }
                            loading={isUpdating}
                        >
                            変更を保存する
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};
