import React from "react";
import { Link, useLocation, useMatch } from "react-router-dom";
import HeaderLogoImg from "../assets/header_logo.svg";
import AngleLeft from "../assets/angle_left.svg";
import AnserwerAddIcon from "../assets/anserwer_add_icon.svg";
import HamburgerMenuButtonImg from "../assets/hamburger_menu.svg";
import { Path } from "../routes/PagePath";
import { useHamburgerMenuInfo } from "../features/hamburgerMenu/useHamburgerMenuInfo";
import useLocationHistory from "../features/locationHistory/hooks/useLocationHistory";

const titles = [
    { text: "やりとり相手一覧", path: Path.answerers },
    { text: "ログイン", path: Path.signin },
    { text: "ユーザー登録", path: Path.signup },
];

export const Header: React.FC = () => {
    const signinMatch = useMatch(Path.signin);
    const signupMatch = useMatch(Path.signup);
    const signupResultMatch = useMatch(Path.signupResult);
    const rootMatch = useMatch(Path.root);
    const answerersMatch = useMatch(Path.answerers);
    const passwordResetSendConfirmationCodeMatch = useMatch(
        Path.passwordResetSendConfirmationCode
    );
    const passwordResetSendConfirmationCodeCompletedMatch = useMatch(
        Path.passwordResetSendConfirmationCodeCompleted
    );
    const passwordResetNewPasswordMatch = useMatch(
        Path.passwordResetNewPassword
    );
    const passwordResetNewPasswordCompletedMatch = useMatch(
        Path.passwordResetNewPasswordCompleted
    );
    const termsMatch = useMatch(Path.terms);

    const { goBack } = useLocationHistory();
    const { pathname } = useLocation();
    const title = titles.find((x) => x.path === pathname);
    const {
        hamburgerMenuInfo,
        hamburgerMenuSlideOpen,
        hamburgerMenuSlideClose,
        hamburgerMenuClose,
    } = useHamburgerMenuInfo();

    const handleClickAngleLeftButton = () => {
        hamburgerMenuClose();
        goBack();
    };

    // 戻るボタンを表示しないページ
    const backPageIconNotDisplayPageMatch = [
        signinMatch,
        signupMatch,
        signupResultMatch,
        rootMatch,
        passwordResetSendConfirmationCodeCompletedMatch,
        passwordResetNewPasswordMatch,
        passwordResetNewPasswordCompletedMatch,
        termsMatch,
    ];

    // ハンバーガーメニューボタンを表示しないページ
    const hamburgerMenuNotDisplayPageMatch = [
        signinMatch,
        signupMatch,
        signupResultMatch,
        passwordResetSendConfirmationCodeMatch,
        passwordResetSendConfirmationCodeCompletedMatch,
        passwordResetNewPasswordMatch,
        passwordResetNewPasswordCompletedMatch,
        answerersMatch,
        termsMatch,
    ];

    return (
        <header className="fixed top-0 left-0 z-40 box-border flex h-16 w-full items-center justify-center border-b-2 border-gray-200 bg-white">
            {!backPageIconNotDisplayPageMatch.find(
                (matchPage) => matchPage
            ) && (
                <button
                    onClick={handleClickAngleLeftButton}
                    className="absolute top-4 left-0 ml-2"
                >
                    <img src={AngleLeft} alt="AngleLeftImg" />
                </button>
            )}

            {title ? (
                <h2>{title.text}</h2>
            ) : (
                <Link onClick={hamburgerMenuClose} to={Path.root}>
                    <img src={HeaderLogoImg} alt="HeaderLogoImg" />
                </Link>
            )}

            {!hamburgerMenuNotDisplayPageMatch.find(
                (matchPage) => matchPage
            ) && (
                <div className="absolute top-4 right-0 mr-3">
                    <button
                        onClick={() =>
                            hamburgerMenuInfo.hamburgerMenuIsOpen
                                ? hamburgerMenuSlideClose()
                                : hamburgerMenuSlideOpen()
                        }
                    >
                        <img
                            src={HamburgerMenuButtonImg}
                            alt="HamburgerButtonImg"
                        />
                    </button>
                </div>
            )}

            {answerersMatch && (
                <div className="absolute top-4 right-0 mr-3">
                    <Link to={Path.tutorial}>
                        <img src={AnserwerAddIcon} alt="AnserwerAddIconImg" />
                    </Link>
                </div>
            )}
        </header>
    );
};
