import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@twioku/common_components";
import { Path } from "../routes/PagePath";
import InputComplete from "../assets/input_complete.svg";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";

export const AnswererCompletePage: React.FC = () => {
    const navigate = useNavigate();
    const { newAnswererInfo } = useNewAnswererInfo();

    const goToNextPage = () => {
        if (newAnswererInfo.questionSendType === "Email") {
            navigate(Path.senderEmailConfirm);
        }

        if (newAnswererInfo.questionSendType === "Line") {
            navigate(Path.senderLineLinkConfirm);
        }
    };

    return (
        <>
            <h1 className="mx-auto mt-14 mb-2 text-center text-2xl font-bold leading-8 text-primary">
                登録
                <br />
                ありがとうございました！
            </h1>
            <h3 className="text-center text-xl font-bold leading-8 text-primary">
                最後に、相手との
                <br />
                やりとり方法の設定をしましょう！
            </h3>
            <img className="mx-auto mt-14" src={InputComplete} />
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={() => goToNextPage()}
                    className="mb-8 mt-2 h-12 w-full"
                >
                    やりとり方法の設定に進む
                </Button>
            </div>
        </>
    );
};
