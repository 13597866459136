import { useCallback, useMemo } from "react";
import { Answerer } from "../../../api/generated";
import { isDate, parseToDate } from "../../../libs/helper";
import {
    getAnswerer,
    sendVerifyEmailToAnswerer,
    updateAnswerer,
    uploadProfileImage,
} from "../../../api/answerer";
import { useAuth } from "../../auth/hooks/useAuth";
import { fileToBase64 } from "../../../libs/fileHelper";
import { EMAIL_REGEX } from "../../../libs/constants";
import { toFormatString } from "../../../libs/helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import zod from "zod";
import { showToast } from "@twioku/common_components";

export const useAnswerer = (id: string) => {
    const queryKey = useMemo(() => ["answerer", id], [id]);
    const queryClient = useQueryClient();

    const { data: answerer, isLoading } = useQuery(queryKey, () =>
        getAnswerer(id)
    );

    const clearCache = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey });
    }, [queryClient, queryKey]);

    return {
        answerer,
        isLoading,
        clearCache,
    };
};

export const updateAnswererInputSchema = zod
    .object({
        name: zod
            .string()
            .min(1, { message: "やりとりしたい相手のお名前は入力必須です。" }),
        relationship: zod.union([
            zod.literal("father"),
            zod.literal("mother"),
            zod.literal("others"),
        ]),
        birthday: zod
            .string()
            .length(8, { message: "日付は８桁で入力してください。" })
            .refine((value) => isDate(value), {
                message: "日付の入力が不正です。",
            }),
        receiverNickname: zod
            .string()
            .min(1, { message: "あなたのニックネームは入力必須です。" }),
        email: zod.string(),
        questionSendType: zod.union([
            zod.literal("Email"),
            zod.literal("Line"),
        ]),
        qmailFrequency: zod
            .array(
                zod.union([
                    zod.literal("Sunday"),
                    zod.literal("Monday"),
                    zod.literal("Tuesday"),
                    zod.literal("Wednesday"),
                    zod.literal("Thursday"),
                    zod.literal("Friday"),
                    zod.literal("Saturday"),
                ])
            )
            .min(1, { message: "最低でも１つは選択してください。" }),
    })
    .refine(
        (data) => {
            // 入力あればメール正規表現チェックをする
            if (data.email) {
                return data.email.match(EMAIL_REGEX);
            }

            // 入力なし
            // やり取り方法がEメールの場合は必須
            return data.questionSendType !== "Email";
        },
        { message: "メールアドレスが不正です。", path: ["email"] }
    );

export type UpdateAnswererInput = zod.infer<typeof updateAnswererInputSchema>;

export const useUpdateAnswerer = (answerer: Answerer) => {
    const { csrfToken } = useAuth();

    const mutation = useMutation({
        mutationFn: async (input: UpdateAnswererInput) => {
            await updateAnswerer(
                answerer.userId,
                answerer.answererId,
                input.name,
                input.receiverNickname,
                input.email,
                toFormatString(parseToDate(input.birthday), true, "-"),
                input.relationship,
                input.questionSendType,
                input.qmailFrequency,
                csrfToken
            );
        },
    });

    return { updateAnswerer: mutation };
};

export const useUploadAnswererProfileImage = (answerer: Answerer) => {
    const { csrfToken } = useAuth();

    const mutation = useMutation({
        mutationFn: async (file: File) => {
            const allowExtensions = ".(jpeg|jpg|png|gif)$";
            if (!file.name.match(allowExtensions)) {
                showToast(
                    "jpeg, jpg, png, gifファイルを選択してください。",
                    "error"
                );
                return;
            }

            const sizeLimit = 1024 * 1024 * 5; // 5MB
            if (file.size > sizeLimit) {
                showToast("ファイルサイズは5MB以下にしてください。", "error");
                return;
            }

            const base64File = await fileToBase64(file);
            return await uploadProfileImage(
                answerer.answererId,
                base64File,
                csrfToken
            );
        },
    });

    return { uploadAnswererProfileImage: mutation };
};

export const useSendVerifyEmailToAnswerer = (answerer: Answerer) => {
    const { csrfToken } = useAuth();

    const mutation = useMutation({
        mutationFn: async () => {
            await sendVerifyEmailToAnswerer(answerer.answererId, csrfToken);
        },
    });

    return { sendVerifyEmailToAnswerer: mutation };
};
