import { TextInput, Button } from "@twioku/common_components";
import { TextLabel } from "../components/TextLabel";
import { useState } from "react";
import { useAuth } from "../features/auth/hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "../routes/PagePath";
import { ErrorPage } from "./ErrorPage";

// FIXME: react-hook-form を使う
export const NewPasswordPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const confirmationCode = searchParams.get("confirmationCode");
    const cognitoUsername = searchParams.get("cognitoUsername");

    const [password, setPassword] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");

    const { isLoading, passwordReset } = useAuth();

    const handlePasswordReset = () => {
        passwordReset(
            cognitoUsername,
            confirmationCode,
            password,
            passwordConfirm,
            () => navigate(Path.passwordResetNewPasswordCompleted)
        );
    };

    if (!confirmationCode || !cognitoUsername) {
        return <ErrorPage />;
    }

    return (
        <div className="mx-6">
            <h1 className="mt-16 mb-3 text-center text-lg">パスワード再設定</h1>
            <div className="mb-16 flex justify-center px-3">
                新しいパスワードを入力してください。
            </div>
            <div className="mb-5">
                <TextLabel htmlFor="password">新しいパスワード</TextLabel>
                <TextInput
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    name="password"
                    value={password}
                    placeholder="ex) 数字/小文字/大文字を含む8文字以上"
                    className="w-full"
                />
            </div>
            <div>
                <TextLabel htmlFor="confirm-password">
                    新しいパスワード（確認）
                </TextLabel>
                <TextInput
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    type="password"
                    name="confirm-password"
                    value={passwordConfirm}
                    placeholder="ex) 数字/小文字/大文字を含む8文字以上"
                    className="w-full"
                />
            </div>
            <p className="mb-7 text-xs text-gray-500">
                ※アルファベットの大文字および小文字、数字、全て含んだ8文字以上が必要です。
            </p>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={handlePasswordReset}
                    type="button"
                    disabled={isLoading}
                    loading={isLoading}
                    className="mb-8 mt-2 h-12 w-full"
                >
                    変更する
                </Button>
            </div>
        </div>
    );
};
