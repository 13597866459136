import { Path } from "./PagePath";

export const pageTitles = [
    { title: "ホーム", path: Path.root },
    { title: "ログイン", path: Path.signin },
    { title: "新規登録", path: Path.signup },
    { title: "新規登録完了", path: Path.signupResult },
    { title: "利用規約", path: Path.terms },
    { title: "確認コード送信", path: Path.passwordResetSendConfirmationCode },
    {
        title: "確認コード送信完了",
        path: Path.passwordResetSendConfirmationCodeCompleted,
    },
    { title: "パスワード再設定", path: Path.passwordResetNewPassword },
    {
        title: "パスワード再設定完了",
        path: Path.passwordResetNewPasswordCompleted,
    },
    { title: "やり取り相手一覧", path: Path.answerers },
    { title: "プロフィール", path: Path.profile },
    { title: "チュートリアル", path: Path.tutorial },
    { title: "やり取り相手", path: Path.answerer },
    { title: "やり取り相手詳細", path: Path.answererDetail },
    {
        title: "やり取り相手詳細（送信頻度選択）",
        path: Path.answererDetailQmailSendFrequency,
    },
    { title: "やり取り相手の名前", path: Path.answerername },
    { title: "やり取り相手との関係", path: Path.answererrelationship },
    { title: "やり取り相手の誕生日", path: Path.answererbirthday },
    { title: "あなたのニックネーム", path: Path.receivernickname },
    { title: "やり取り相手のメールアドレス", path: Path.answereremail },
    { title: "記憶詳細", path: Path.memorydetail },
    { title: "問いかけメール送信頻度", path: Path.qmailsendfrequency },
    { title: "やり取り相手詳細確認", path: Path.answererconfirm },
    { title: "やり取り相手登録完了", path: Path.answerercomplete },
    { title: "メールアドレス確認", path: Path.senderEmailConfirm },
    { title: "確認メール送信完了", path: Path.sendEmailCompleted },
    { title: "利用案内確認", path: Path.useStartMailRemind },
    { title: "メールアドレス確認", path: Path.emailConfirm },
    { title: "エラー", path: Path.error },
    { title: "ページが見つかりません", path: Path.notFound },
];
