import React, { useRef, useState } from "react";
import answererIconImg from "../../../assets/answerer_icon.svg";
import audioPlayImg from "../../../assets/audio_play.svg";
import audioPauseImg from "../../../assets/audio_pause.svg";

type Props = {
    profileImagePath: string | undefined;
    voiceAudioPath: string | undefined;
    onPlay?: () => void;
    onEnded?: () => void;
};

export const AudioMemoryItem = (props: Props) => {
    const { profileImagePath, voiceAudioPath, onPlay, onEnded } = props;
    const audioRef = useRef<HTMLAudioElement>(null);
    const [playing, setPlaying] = useState(false);
    const handleClickPlayAudio = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!voiceAudioPath) return;

        if (playing) {
            audioRef.current?.pause();
        } else {
            audioRef.current?.play();
            onPlay && onPlay();
        }

        setPlaying(!playing);
    };
    const handleEndedAudio = () => {
        setPlaying(false);
        onEnded && onEnded();
    };
    const audioPlayingClassName = () => {
        return playing ? "animate-expandingCircle" : "";
    };
    return (
        <>
            <div className="flex h-48 items-center justify-center">
                <button
                    className="absolute z-30 h-16 w-16 rounded-full"
                    onClick={handleClickPlayAudio}
                >
                    <div className="h-full w-full">
                        <img
                            src={
                                profileImagePath
                                    ? profileImagePath
                                    : answererIconImg
                            }
                            className="absolute h-full w-full rounded-full"
                        />
                        <img
                            src={playing ? audioPauseImg : audioPlayImg}
                            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                        />
                    </div>
                </button>
                <div
                    className={`absolute z-20 h-20 w-20 rounded-full bg-white ${audioPlayingClassName()}`}
                ></div>
                <div
                    className={`absolute z-10 h-24 w-24 rounded-full bg-gray-100 ${audioPlayingClassName()}`}
                ></div>
                <div
                    className={`absolute h-32 w-32 rounded-full bg-gray-300 ${audioPlayingClassName()}`}
                ></div>
            </div>
            <audio
                ref={audioRef}
                src={voiceAudioPath}
                onEnded={handleEndedAudio}
            />
        </>
    );
};
