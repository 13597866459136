/* tslint:disable */
/* eslint-disable */
/**
 * Twioku-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Answerer
 */
export interface Answerer {
    /**
     * 
     * @type {string}
     * @memberof Answerer
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof Answerer
     */
    answererId: string;
    /**
     * 
     * @type {string}
     * @memberof Answerer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Answerer
     */
    receiverNickname: string;
    /**
     * 
     * @type {string}
     * @memberof Answerer
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Answerer
     */
    questionSendType: string;
    /**
     * 
     * @type {string}
     * @memberof Answerer
     */
    birthday: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Answerer
     */
    qmailFrequency: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Answerer
     */
    relationship: string;
    /**
     * 
     * @type {string}
     * @memberof Answerer
     */
    profileImagePath?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Answerer
     */
    lineLinked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Answerer
     */
    isPrivacyAndUsageTermsAgreed?: boolean;
}

/**
 * Check if a given object implements the Answerer interface.
 */
export function instanceOfAnswerer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "answererId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "receiverNickname" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "questionSendType" in value;
    isInstance = isInstance && "birthday" in value;
    isInstance = isInstance && "qmailFrequency" in value;
    isInstance = isInstance && "relationship" in value;
    isInstance = isInstance && "lineLinked" in value;

    return isInstance;
}

export function AnswererFromJSON(json: any): Answerer {
    return AnswererFromJSONTyped(json, false);
}

export function AnswererFromJSONTyped(json: any, ignoreDiscriminator: boolean): Answerer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'answererId': json['answererId'],
        'name': json['name'],
        'receiverNickname': json['receiverNickname'],
        'email': json['email'],
        'questionSendType': json['questionSendType'],
        'birthday': json['birthday'],
        'qmailFrequency': json['qmailFrequency'],
        'relationship': json['relationship'],
        'profileImagePath': !exists(json, 'profileImagePath') ? undefined : json['profileImagePath'],
        'lineLinked': json['lineLinked'],
        'isPrivacyAndUsageTermsAgreed': !exists(json, 'isPrivacyAndUsageTermsAgreed') ? undefined : json['isPrivacyAndUsageTermsAgreed'],
    };
}

export function AnswererToJSON(value?: Answerer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'answererId': value.answererId,
        'name': value.name,
        'receiverNickname': value.receiverNickname,
        'email': value.email,
        'questionSendType': value.questionSendType,
        'birthday': value.birthday,
        'qmailFrequency': value.qmailFrequency,
        'relationship': value.relationship,
        'profileImagePath': value.profileImagePath,
        'lineLinked': value.lineLinked,
        'isPrivacyAndUsageTermsAgreed': value.isPrivacyAndUsageTermsAgreed,
    };
}

