import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@twioku/common_components";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";
import { Path } from "../routes/PagePath";
import LineImg from "../assets/line_icon.png";

export const AnswererLinePreparationPage: React.FC = () => {
    const navigate = useNavigate();
    const { newAnswererInfo } = useNewAnswererInfo();

    return (
        <>
            <div className="px-6">
                <h1 className="mt-5 mb-5 text-center text-xl font-bold">
                    分かりました！
                    {newAnswererInfo.name}
                    さんにはLINEをご利用いただきます。
                </h1>
                <div className="mb-2 text-center">
                    ※アカウントの連携はこの後の手順で行います。
                </div>
                <div className="mt-5 flex justify-center">
                    <img src={LineImg} width={242} height={242} />
                </div>
                <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                    <Button
                        onClick={() => navigate(Path.qmailsendfrequency)}
                        className="mb-8 mt-2 h-12 w-full"
                    >
                        次へ
                    </Button>
                </div>
            </div>
        </>
    );
};
