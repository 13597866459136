import React from "react";

type Props = {
    label: string;
    text: string;
};

export const ConfirmContentItem: React.FC<Props> = (props: Props) => {
    const { label, text } = props;
    return (
        <div className="w-full break-words border-b-2 border-gray-200 pt-1 pb-2">
            <div className="mx-5">
                <span className="block text-xs text-gray-400">{label}</span>
                <div className="flex justify-between">
                    <span className="ml-1 block">{text}</span>
                </div>
            </div>
        </div>
    );
};
