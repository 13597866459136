/* tslint:disable */
/* eslint-disable */
/**
 * Twioku-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Comment } from './Comment';
import {
    CommentFromJSON,
    CommentFromJSONTyped,
    CommentToJSON,
} from './Comment';

/**
 * 
 * @export
 * @interface Memory
 */
export interface Memory {
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    answererId: string;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    answererName: string;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    respondDate: string;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    questionId: string;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    sendDate: string;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    respondContent: string;
    /**
     * 
     * @type {number}
     * @memberof Memory
     */
    likedCount: number;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof Memory
     */
    comments: Array<Comment>;
    /**
     * 
     * @type {boolean}
     * @memberof Memory
     */
    isUnread: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Memory
     */
    hasUnreadComment: boolean;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    answererProfileImagePath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Memory
     */
    memoryImagePaths?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    memoryVoiceAudioPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Memory
     */
    memoryVideoPaths?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    createAt: string;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    responseStatus: string;
}

/**
 * Check if a given object implements the Memory interface.
 */
export function instanceOfMemory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "answererId" in value;
    isInstance = isInstance && "answererName" in value;
    isInstance = isInstance && "respondDate" in value;
    isInstance = isInstance && "questionId" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "sendDate" in value;
    isInstance = isInstance && "respondContent" in value;
    isInstance = isInstance && "likedCount" in value;
    isInstance = isInstance && "comments" in value;
    isInstance = isInstance && "isUnread" in value;
    isInstance = isInstance && "hasUnreadComment" in value;
    isInstance = isInstance && "createAt" in value;
    isInstance = isInstance && "responseStatus" in value;

    return isInstance;
}

export function MemoryFromJSON(json: any): Memory {
    return MemoryFromJSONTyped(json, false);
}

export function MemoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Memory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'id': json['id'],
        'answererId': json['answererId'],
        'answererName': json['answererName'],
        'respondDate': json['respondDate'],
        'questionId': json['questionId'],
        'title': json['title'],
        'category': json['category'],
        'sendDate': json['sendDate'],
        'respondContent': json['respondContent'],
        'likedCount': json['likedCount'],
        'comments': ((json['comments'] as Array<any>).map(CommentFromJSON)),
        'isUnread': json['isUnread'],
        'hasUnreadComment': json['hasUnreadComment'],
        'answererProfileImagePath': !exists(json, 'answererProfileImagePath') ? undefined : json['answererProfileImagePath'],
        'memoryImagePaths': !exists(json, 'memoryImagePaths') ? undefined : json['memoryImagePaths'],
        'memoryVoiceAudioPath': !exists(json, 'memoryVoiceAudioPath') ? undefined : json['memoryVoiceAudioPath'],
        'memoryVideoPaths': !exists(json, 'memoryVideoPaths') ? undefined : json['memoryVideoPaths'],
        'createAt': json['createAt'],
        'responseStatus': json['responseStatus'],
    };
}

export function MemoryToJSON(value?: Memory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'id': value.id,
        'answererId': value.answererId,
        'answererName': value.answererName,
        'respondDate': value.respondDate,
        'questionId': value.questionId,
        'title': value.title,
        'category': value.category,
        'sendDate': value.sendDate,
        'respondContent': value.respondContent,
        'likedCount': value.likedCount,
        'comments': ((value.comments as Array<any>).map(CommentToJSON)),
        'isUnread': value.isUnread,
        'hasUnreadComment': value.hasUnreadComment,
        'answererProfileImagePath': value.answererProfileImagePath,
        'memoryImagePaths': value.memoryImagePaths,
        'memoryVoiceAudioPath': value.memoryVoiceAudioPath,
        'memoryVideoPaths': value.memoryVideoPaths,
        'createAt': value.createAt,
        'responseStatus': value.responseStatus,
    };
}

