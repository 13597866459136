import React from "react";
import { useNavigate } from "react-router-dom";
import { TextInput, Button } from "@twioku/common_components";
import { TextLabel } from "../components/TextLabel";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";
import { Path } from "../routes/PagePath";

// FIXME: react-hook-form を使う
export const AnswererNamePage: React.FC = () => {
    const navigate = useNavigate();
    const { newAnswererInfo, updateAnswererName, isError, errorMessage } =
        useNewAnswererInfo();

    return (
        <div className="px-6">
            <h1 className="mt-5 mb-8 text-center text-xl font-bold">
                相手のお名前(フルネーム)
                <br />
                を教えてください！
            </h1>
            <div>
                <TextLabel className="font-bold text-primary">
                    やりとりしたい相手のお名前
                </TextLabel>
                <TextInput
                    onChange={(e) => updateAnswererName(e.target.value)}
                    type="text"
                    placeholder="例) 田中 母子"
                    value={newAnswererInfo.name}
                    errorMessage={errorMessage}
                    className="w-full"
                />
            </div>
            <p className="mb-5 mt-8 text-sm text-gray-600">
                ※お相手の方への通知メールなどに使用します。
                <br />
                お相手の方がご自身のことを指している事がわかるよう、フルネームを入力してください。
            </p>
            <div className="rounded-lg bg-gray-100 p-2">
                <p className="text-xs text-gray-500">メール文例)</p>
                <p className="py-3 text-sm leading-6 text-gray-600">
                    <strong className="underline">
                        {newAnswererInfo.name.length > 0
                            ? newAnswererInfo.name
                            : "田中 母子"}
                    </strong>
                    さん こんにちは！
                    <br />
                    ツイオクからのお知らせです！
                </p>
            </div>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={() => navigate(Path.answererrelationship)}
                    className="mb-8 mt-2 h-12 w-full"
                    disabled={isError}
                >
                    次へ
                </Button>
            </div>
        </div>
    );
};
