import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@twioku/common_components";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";
import { sortFrequencyJapanese } from "../libs/helper";
import { Path } from "../routes/PagePath";

export const SendEmailCompletedPage: React.FC = () => {
    const navigate = useNavigate();
    const { newAnswererInfo } = useNewAnswererInfo();
    const qmailSendFrequencyJapanese = sortFrequencyJapanese(
        newAnswererInfo.qmailSendFrequency
    ).join(",");

    return (
        <div className="mx-6 flex h-full flex-col">
            <h1 className="mx-auto mt-3 text-center text-2xl font-bold">
                <p>
                    {newAnswererInfo.name}
                    さんに、ご利用開始案内のメールを送信しました！
                </p>
            </h1>
            <div className="mt-3 flex-1 overflow-y-auto pb-32">
                <div className="mb-3 w-full break-words border-gray-200 pt-1 pb-2">
                    <span className="ml-1 block">
                        <p>{newAnswererInfo.name}さんのメールアドレス</p>
                        <p>
                            {newAnswererInfo.email}
                            にご利用開始案内のメールを送信しました！
                        </p>
                        <br />
                        <p>
                            このご利用開始案内のメールに、{newAnswererInfo.name}
                            さんがご返信いただくことで、
                            ツイオクの利用開始に同意いただけたとして、ツイオクのサービスが開始されます。
                        </p>
                        <br />
                        <p>
                            メールにご返信いただきますよう{newAnswererInfo.name}
                            さんにご確認いただけますと、
                            確実にツイオクを始めることができます！
                        </p>
                        <br />
                        <p>
                            ご利用開始以降は、毎週{qmailSendFrequencyJapanese}
                            に問いかけメールが
                            {newAnswererInfo.name}さんに自動で送信されます。
                        </p>
                        <p>
                            {newAnswererInfo.name}
                            さんが回答をメールで返信いただくだけで、
                            あなたは当アプリで想い出の内容を確認できます！
                        </p>
                        <br />
                        <p>
                            ※もしメールが届いて無いようでしたら、お手数ですがお問い合わせ頂けますようお願いいたします。
                        </p>
                    </span>
                </div>
            </div>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={() => navigate(Path.root)}
                    className="mb-8 mt-2 h-12 w-full"
                >
                    連絡・確認しました！
                </Button>
            </div>
        </div>
    );
};
