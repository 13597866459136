import React from "react";
import { useNavigate } from "react-router-dom";
import { TextInput, Button } from "@twioku/common_components";
import { TextLabel } from "../components/TextLabel";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";
import { Path } from "../routes/PagePath";

// FIXME: react-hook-form を使う
export const ReceiverNicknamePage: React.FC = () => {
    const navigate = useNavigate();
    const { isError, errorMessage, newAnswererInfo, updateReceiverNickname } =
        useNewAnswererInfo();

    return (
        <>
            <div className="px-6">
                <h1 className="mt-5 mb-8 text-center text-xl font-bold">
                    {newAnswererInfo.name}
                    さんがあなたをどう呼んでいるか教えてください！
                </h1>
                <div>
                    <TextLabel className="font-bold text-primary">
                        ({newAnswererInfo.name}さんの)あなたの呼び方
                    </TextLabel>
                    <TextInput
                        onChange={(e) => updateReceiverNickname(e.target.value)}
                        type="text"
                        value={newAnswererInfo.receiverNickname}
                        placeholder="例) たけし、はなこ"
                        errorMessage={errorMessage}
                        className="w-full"
                    />
                </div>
                <p className="mb-5 mt-8 text-sm text-gray-600">
                    ※お相手の方への通知メールなどに使用します。
                    <br />
                    あなたのことについて聞かれていることがわかるような呼称を入力してください。
                </p>
                <div className="rounded-lg bg-gray-100 p-2">
                    <p className="text-xs text-gray-500">メール文例)</p>
                    <p className="py-3 text-sm leading-6 text-gray-600">
                        {newAnswererInfo.name}さん こんにちは！
                    </p>
                    <p className="py-3 text-sm leading-6 text-gray-600">
                        <strong className="underline">
                            {newAnswererInfo.receiverNickname.length > 0
                                ? newAnswererInfo.receiverNickname
                                : "たけし"}
                        </strong>
                        さんの子供の頃について教えてください。
                    </p>
                </div>
                <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                    <Button
                        onClick={() => navigate(Path.answererQuestionSendType)}
                        className="mb-8 mt-2 h-12 w-full"
                        disabled={isError}
                    >
                        次へ
                    </Button>
                </div>
            </div>
        </>
    );
};
