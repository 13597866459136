import { QuestionSendType } from "../stores/appContext";
import { client, DEFAULT_HEADERS } from "./defaultClient";
import { Answerer } from "./generated";

export const getAnswerers = async (): Promise<Answerer[]> =>
    await client.getUsersMeAnswerers({ mode: "cors" });

export const getAnswerer = async (answererId: string): Promise<Answerer> =>
    await client.getUsersMeAnswerersAnswererId(
        { answererId },
        { mode: "cors" }
    );

export const createAnswerer = async (
    name: string,
    receiverNickname: string,
    questionSendType: QuestionSendType,
    email: string,
    birthday: string,
    relationship: string,
    qmailFrequency: string[],
    csrfToken: string
) => {
    return await client.postUsersMeAnswerers(
        {
            newAnswerer: {
                name: name,
                receiverNickname: receiverNickname,
                email: email,
                birthday: birthday,
                relationship: relationship,
                qmailFrequency: qmailFrequency,
                questionSendType: questionSendType,
            },
        },
        {
            mode: "cors",
            headers: {
                ...DEFAULT_HEADERS,
                "X-CSRF-Token": csrfToken,
            },
        }
    );
};

export const updateAnswerer = async (
    _userId: string, // TODO: 未使用なので削除する
    answererId: string,
    name: string,
    receiverNickname: string,
    email: string,
    birthday: string,
    relationship: string,
    questionSendType: string,
    qmailFrequency: string[],
    csrfToken: string
) => {
    return await client.patchUsersMeAnswerersAnswererId(
        {
            answererId: answererId,
            patchUsersMeAnswerersAnswererIdRequest: {
                name: name,
                receiverNickname: receiverNickname,
                email: email,
                birthday: birthday,
                relationship: relationship,
                questionSendType,
                qmailFrequency: qmailFrequency,
            },
        },
        {
            mode: "cors",
            headers: {
                ...DEFAULT_HEADERS,
                "X-CSRF-Token": csrfToken,
            },
        }
    );
};

export const sendVerifyEmailToAnswerer = async (
    answererId: string,
    csrfToken: string
) => {
    return await client.postUsersMeAnswerersAnswererIdVerifyEmail(
        {
            answererId,
        },
        {
            mode: "cors",
            headers: {
                ...DEFAULT_HEADERS,
                "X-CSRF-Token": csrfToken,
            },
        }
    );
};

export const uploadProfileImage = async (
    answererId: string,
    fileImageToBase64: string,
    csrfToken: string
): Promise<string> => {
    const response = await client.postUsersMeAnswererUploadProfileImage(
        {
            postUsersMeAnswererUploadProfileImageRequest: {
                fileImageToBase64: fileImageToBase64,
                answererId: answererId,
            },
        },
        {
            mode: "cors",
            headers: {
                ...DEFAULT_HEADERS,
                "X-CSRF-Token": csrfToken,
            },
        }
    );
    return response.filePath ?? "";
};
